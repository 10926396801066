<template>
  <list-template
    :table-config="tableConfig"
    :table-data="tableData"
    :total="total"
    :loading="loading"
    :current-page="page"
    @onChangePage="handleCurrentChange"
    @onChange="onChange"
  >
  <template #title>
    <div class="mb-3 pt-1" style="font-size: 24rem;font-weight: 600;">页面转化率统计</div>
  </template>
  </list-template>
</template>

<script>
import { tableListMixin } from '@/utils/mixins'
export default {
	_config:{"route":{"path":"list","meta":{"title":"统计"}}},
  mixins: [tableListMixin],
  methods: {
    getData() {
      this.getDataApi('/zhaosheng/api/crm/statistics-list')
    },
  },
  data() {
    return {
      tableConfig: [
        { prop: 'day', label: '日期' },
        { prop: 'thread_page_pv', label: '进入线索页（PV）' },
        { prop: 'thread_user_uv', label: '进入线索页（UV）' },
        { prop: 'code_pv', label: '获取验证码（PV）' },
        { prop: 'code_uv', label: '获取验证码（UV）' },
        { prop: 'submit_pv', label: '点提交按钮（PV）' },
        { prop: 'submit_uv', label: '点提交按钮（UV）' },
        { prop: 'total', label: '收集线索数' }
      ]
    }
  },
}
</script>

<style lang="scss" scoped></style>
